import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'
import VueZoomer from 'vue-zoomer'
import VueFullscreen from 'vue-fullscreen'
import {errorMixin as myError} from '@/plugins/error.plugin'
import '@/css/main.css'


const app =createApp(App);
app.directive('scroll', {
  beforeMount(el, binding, vnode) {
    el.classList.add("uaz_div_with_scroll");
  }
});
app.use(store).use(router).use(VueZoomer).use(ElementPlus).use(VueUniversalModal, {
    teleportTarget: '#modals'
  }).use(VueFullscreen).use(myError as any).mount('#app');

 

