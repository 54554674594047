<template>
  <el-table
    :data="data"
    :class="className"
    style="width: 100%"
    :cell-class-name="uazbodycell"
    :header-cell-class-name="uazheadercell"
    :row-class-name="uazbodyrow"
    :header-row-class-name="uazheaderrow"
    :empty-text="notFound"
    @row-click="handleRowClick"
    @sort-change="handleSort"
    :span-method="spanMethod"
    :height="height ? height : null"
  >
    <el-table-column
      v-for="(column, index) in columns"
      :key="index"
      :label="column.label"
      :width="column.width"
      :prop="column.prop"
      :sortable="column.sortable"
    >
       <template #default="scope">
        <div v-if="column.html" v-html="scope.row[column.prop]" />
        <div v-else-if="!!column.formatter">
          {{ column.formatter(scope.row) }}
        </div>		
        <div v-else>
          <div @change="handleChange" v-if="column.prop =='modifications'">
              <div :key="index2" v-for="(row2,key2,index2) in scope.row[column.prop]">
                <span @click="goToEmaps(row2,scope.row)">{{row2.productModel_name}} - {{row2.productModification_name}}</span>
              </div>
          </div>
          <div v-else>
            <span>{{ scope.row[column.prop] }}</span>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      orders: [null],
      uazheadercell: "uaz-header-cell",
      uazbodycell: "uaz-body-cell",
      uazbodyrow: "uaz-body-row",
      uazheaderrow: "uaz-header-row",
      notFound: "Элементы не найдены",
    };
  },
  props: ["data", "columns", "handleRowClick", "handleSort", "spanMethod", "className", "height"],
  methods: {
    goToEmaps(a,b) {
      this.$store.commit('emaps/SET_BREADCRUMBS',[]);
      console.log(a,'a');
      console.log(b,'b');
      this.$router.push(`/emaps/illustration/detail/model-${a.productModel_id}/modification-${a.productModification_id}/year-${a.period}/illustration-${b.electricGroup_id}`);
      this.$store.commit('section/SET_PAGE_LEVEL',2);
    },
  },
};
</script>
<style>
.uaz-header-cell {
  font-size: 10px;
  font-family: Arial;
  color: #fff;
  text-transform: uppercase;
  line-height: 17px !important;
  background-color: #013e2c !important;
  padding: 0 !important;
}
.uaz-header-row {
}
.uaz-body-row {
/*  background-color: #cfdbd9 !important;*/
}
.uaz-body-cell {
  color: #000;
  font-family: "Arial";
  line-height: 17px !important;
  padding: 0 !important;
  font-weight: 600;
  font-size: 10px;
}
.el-table .cell{
  color: #000;
  font-family: "Arial";
  line-height: 17px;
  padding: 0px 6px;
  font-weight: 600;
  font-size: 10px;  
  text-align: left;
  word-break: break-word;
}
.el-table th.el-table__cell>.cell {
  color: #fff;
  width: 104%;
}
.el-table {
  background-color: #cfdbd9;
  --el-table-row-hover-background-color: inherit;
}
.el-table tr {
  background-color: inherit;
  cursor: pointer;
}
.el-table__body, .el-table__footer, .el-table__header {
  border-collapse: collapse;
}
.el-table td.el-table__cell {
  border: var(--el-table-border);
}
.el-table th.el-table__cell.is-leaf {
  border : none;
}
.el-table td.el-table__cell div > p{
  margin:0;
}
.el-table tr td:first-child {
  text-align: center;
}
.el-table__empty-text {
  font-size: 10px;
  font-family: Arial;  
  color: #000;
}
.el-table__row:hover {
  background: inherit;
}
.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: inherit; 
}
.el-table__empty-block {
  align-items: start;
}
tr.uaz-header-row .cell {
  white-space: nowrap;
  word-break: break-all;
}
</style>