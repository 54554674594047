<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <p class="uaz__flowchart-title">{{ data.flowChartItem_name }}</p>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: ["data"],
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style>
.uaz__flowchart-title {
  font-size: 16px;
}
</style>