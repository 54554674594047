function modificationNameFormatter(productModification_name) {
  return String(productModification_name)
    .split("")
    .reduce((acc, item, index) => {
      acc = acc + item;
      if (index === 5 || index === 12) {
        acc = acc + "-";
      }
      //return acc;
      //return !parseInt(acc.replace(/\W/g)) || acc;
	  return (!parseInt(acc.replace(/\W/g)))? productModification_name : acc;
    }, "");
}

export { 
	modificationNameFormatter
}