<template>
    <header class="uaz__header">
        <div class="uaz__logo_wrapper" @click="goToMain">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 75 33" style="enable-background:new 0 0 75 33;">
                <g>
                    <g>
                        <text class="header_st1" x="3" y="26">
                            <tspan>
                            Электронный
                            </tspan>
                        </text>
                        <text class="header_st1" x="3" y="33">
                            <tspan>
                            каталог запчастей
                            </tspan>
                        </text>
                    </g>
                    <g>
                        
                        <path class="header_st0" d="M64.2,3.9c1.8,0,2.7,0.7,3.3,1.3c0.3,0.3,0.7,0.2,0.8,0.2c0.7-0.2,2.3-0.7,3-0.9c0.1,0,0.2-0.1,0.2-0.2
                            s0-0.2,0-0.3c-1.9-3.6-5.7-4-7.3-4c-3.9,0-8.1,2.5-8.1,8.1c0,5.3,4.1,8,8.1,8c3.4,0,6-1.4,7.2-3.8c0.1-0.1,0.1-0.3,0-0.4
                            c0-0.1-0.1-0.2-0.3-0.3c-0.5-0.2-1.8-0.6-2.6-0.9l-0.3-0.1c-0.3-0.1-0.6,0-0.8,0.3c-0.7,0.8-1.8,1.3-3.3,1.3
                            c-2.2,0-3.7-1.7-3.7-4.1C60.5,5.6,62,3.9,64.2,3.9z"/>
                        <path class="header_st0" d="M54.1,0.3h-2.7c-0.1,0-0.2,0-0.2,0.1l-7.9,7.8c-0.2,0.2-0.6,0.1-0.6-0.2V0.6c0-0.2-0.1-0.3-0.3-0.3h-3.3
                            c-0.2,0-0.3,0.1-0.3,0.3v14.9c0,0.2,0.1,0.3,0.3,0.3h2.7c0.1,0,0.2,0,0.2-0.1l7.9-8.1c0.2-0.2,0.6-0.1,0.6,0.2v7.7
                            c0,0.2,0.1,0.3,0.3,0.3h3.3c0.2,0,0.3-0.1,0.3-0.3V0.6C54.4,0.4,54.3,0.3,54.1,0.3z"/>
                        <path class="header_st0" d="M31.1,0.5c0-0.1-0.2-0.2-0.3-0.2h-5.7c-0.1,0-0.2,0.1-0.3,0.2l-6.6,14.9c-0.1,0.2,0.1,0.4,0.3,0.4H23
                            c0.1,0,0.2-0.1,0.3-0.2l4.3-10.9c0-0.1,0.1-0.2,0.2-0.2H28c0.1,0,0.2,0.1,0.2,0.2l4.3,10.9c0,0.1,0.2,0.2,0.3,0.2h4.6
                            c0.2,0,0.4-0.2,0.3-0.4L31.1,0.5z"/>
                        <path class="header_st0" d="M10.8,0c-3.4,0-6,1.4-7.2,3.8c-0.1,0.1-0.1,0.3,0,0.4c0,0.1,0.1,0.2,0.3,0.3c0.5,0.2,1.8,0.6,2.6,0.9l0.3,0.1
                            c0.3,0.1,0.6,0,0.8-0.3c0.7-0.8,1.8-1.3,3.3-1.3c1.7,0,2.9,1,3.5,2.5h-3.9c-0.2,0-0.3,0.1-0.3,0.3v2.8c0,0.2,0.1,0.3,0.3,0.3h3.9
                            c-0.5,1.5-1.8,2.4-3.5,2.4c-1.8,0-2.7-0.7-3.3-1.3c-0.3-0.3-0.7-0.2-0.8-0.2c-0.7,0.2-2.3,0.7-3,0.9c-0.1,0-0.2,0.1-0.2,0.2
                            c0,0.1,0,0.2,0,0.3c1.9,3.6,5.7,3.9,7.3,3.9c3.9,0,8.1-2.5,8.1-8.1C18.9,2.8,14.8,0,10.8,0z"/>
                    </g>
                </g>
        </svg>
    </div>
    <div class="uaz__header___tab_part">
        <div class="tab_wrapper">
            <el-tabs v-model="selectedTab" type="card" @tab-click="handleClick">
                <el-tab-pane name="parts" label="Запчасти"></el-tab-pane>
                <el-tab-pane name="standtime" label="Нормативы времени"></el-tab-pane>
                <el-tab-pane name="flowcharts" label="Техкарты"></el-tab-pane>
                <el-tab-pane name="emaps" label="Электросхемы"></el-tab-pane>
                <el-tab-pane name="diagnostic" label="Диагностика"></el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <div class="uaz__header___flex_part"></div>
    <div class="uaz__header___menu_part">
    <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    background-color="#013e2c"
    text-color="#fff"
    :ellipsis="false"
  >
    <el-sub-menu index="1" popper-class="header_submenu_select_option">
      <template #title>Документы</template>
      <el-menu-item class="submenu_select_option" index="1-1">API</el-menu-item>
      <el-menu-item class="submenu_select_option" index="1-2">Старый каталог УАЗ (только в IE)</el-menu-item>
      
    </el-sub-menu>
    <el-sub-menu index="2" popper-class="header_submenu_select_option">
        <template #title>Язык</template>
        <el-menu-item @click="setLang('RU')" :class="['submenu_select_option', {'active': activeLang =='RU'}]" index="2-1">RU</el-menu-item>
        <el-menu-item @click="setLang('EN')" :class="['submenu_select_option', {'active': activeLang =='EN'}]" index="2-2">EN</el-menu-item>
    </el-sub-menu>
    </el-menu>
    <div class="uaz__header__logout" @click="logOut">Выйти</div>
    </div>

    <div class="uaz__header__logo_part">
        <img src="../assets/logo.png" />
    </div>
</header>
<div :class="['uaz__subheader',
			  'uaz__subheader_'+ section,
			  {'uaz__subheader_list':currentComponent == 'List'},
			  {'uaz__subheader_test':currentComponent == 'Test'},
			  {'uaz__subheader_result':currentComponent == 'Result'},
			  {'uaz__subheader_session':currentComponent == 'Session'},
			  {'uaz__subheader_report':currentComponent == 'Report'},
			 ]">
  <div style="display: flex;">
    <div class="search_block">
        <search />        
        <div id="header-middle" />
    </div>
	<!--section !== 'diagnostic'-когда выходим из вкладки Диагностика(кнопки Сброс/История),Модель/Год-исчезали-->
    <selection-panel/> 
  </div>
    <div class="uaz__bar_header">
        <through-vin-panel/>
        <left-bar-header/>		
        <right-bar-header/>	
    </div>
</div>
<AlertDialog
    :open="alertOpen"
    bodyModal="Вы действительно хотите завершить сеанс диагностики?"
    headModal="ПОДТВЕРДИТЕ ЗАВЕРШЕНИЕ ДИАГНОСТИКИ"
    :confirmClick="handleConfirm"
    :cancelClick="handleCancel"
/>
</template>

<script>
import Trough_vin_panel from '@/components/search/trough_vin_panel.vue'
import Selection_panel from '@/components/header/Selection_panel.vue'
import Right_bar_header from '@/components/Right_bar_header.vue'
import Left_bar_header from '@/components/Left_bar_header.vue'
import search from '@/components/search/search.vue'
import SelectFilterable from "@/components/common/UI/SelectFilterable.vue";
import AlertDialog from "@/components/diagnostic/AlertDialog.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { types } from "@/store/modules/diagnostic";
export default {
  name: 'Header',
  data() {
      return {
        selectedTab:this.section,
        activeIndex:1,
		activeLang: 'RU',
        alertOpen:false,
        hideBar:false
      }
  },
  components: {
    "right-bar-header": Right_bar_header,
    "left-bar-header": Left_bar_header,
    "through-vin-panel": Trough_vin_panel,
    "selection-panel": Selection_panel,
    search,
    AlertDialog,
	SelectFilterable,
  },
  mounted: function() {
    this.selectedTab = this.section;
    this.activeLang = this.activeLanguage;	
   
  },
  methods:{
    ...mapMutations({
      setProp: `emaps/breadcrumbs`,
      setPropDiagnostic: `diagnostic/SET_PROP`,
      toClearSession: `diagnostic/${types.SET_CLEAR}`,
      toClearTesting: `diagnostic/testing/${types.SET_CLEAR}`,
    }),
    setLang : function(val){
	  this.activeLang = val;
	  this.$store.commit('lang/SET_ACTIVE_LANGUAGE',val);
    },
      handleClick(tab, event) {

        if(tab.props.name == 'diagnostic' && this.section == 'diagnostic' && this.defectId){
           this.openAlert();
        }
        else{
            this.$router.push('/'+this.selectedTab);
        }
      },
      goToMain(){
        this.selectedTab = "parts";
        window.location = '/parts';
        this.$store.commit('section/SET_PAGE_LEVEL',0);
      },
      checkAvailableInChapter: function(permissionObject,section){
            let havePermission = false;
            switch(section){
            case 'parts':
                havePermission = permissionObject.showInParts;
                break;
            case 'standtime':
                havePermission = permissionObject.showInLaborTime;
                break;
            case 'flowcharts':
                havePermission = permissionObject.showInFlowchart;
                break;
            case 'emaps':
                havePermission = permissionObject.showInEmaps;
                break;
            case 'diagnostic':
                havePermission = permissionObject.showInDiagnostic;
                break;                             
            }
            return havePermission;
      },
      logOut(){
        this.$store.commit('AUTH_LOGOUT');
        this.$store.commit('vinThrough/SET_VIN_STATUS',"not set");
        this.$store.commit('vinThrough/SET_THROUGH_VIN',"");
        this.$store.commit('model/SET_ID', 0);
        this.$store.commit('modification/SET_ID', 0);
        this.$router.push('/auth');
        this.$store.commit('section/SET_PAGE_LEVEL',-1);
      },
      openAlert() {
        this.alertOpen = true;
      },
      handleConfirm() {
        this.setPropDiagnostic({ meta: "defectId", data: "" });
        this.toClearSession();
        this.toClearTesting();
        this.$router.push('/diagnostic');
        this.alertOpen = false;
      },
      handleCancel() {
        this.alertOpen = false;
      },
      destroySession() {
        this.toClearTesting();
        this.toClearSession();
      },
  },
  computed: {
    ...mapGetters({
        modificationID: "modification/getModification_id",
        modelID: "model/getModel_id"
    }),	
    ...mapState({
      currentComponent: (state) => state.diagnostic.testing.currentComponent,
      defectId: (state) => state.diagnostic.defectId,
      THROUGH_VIN: (state) =>state.vinThrough.THROUGH_VIN,
      section: (state) => state.section.section,
    }),	
    ...mapGetters({
      panelDiagnostic: "diagnostic/getDiagnosticData",
   }),
    ...mapGetters({
        activeLang: "lang/getActiveLanguage"
    }),	 
    ...mapState({
        activeLanguage: (state) => {
          return state.lang.activeLanguage;
        },
    })
  },
  watch: {
    $route(to, from) {
        let section = to.path.split('/')[1];
        if(!['parts','standtime','flowcharts','emaps','diagnostic'].includes(section)){
            console.log("could not recognize default page",'error');
        }
        if(section == 'flowcharts'){
            if(this.modificationID && this.modelID && !this.$route.params.flowchartId && this.THROUGH_VIN.showInFlowchart){
                if(this.THROUGH_VIN.showInParts){ 
                    this.$router.push('/flowcharts/models-'+ this.modelID + '/modification-' + this.modificationID +'/');
                    this.$store.commit('section/SET_PAGE_LEVEL',1);
                }
                else{
                    this.$router.push('/flowcharts/search/flowcharts-list/');
                    this.$store.commit('section/SET_PAGE_LEVEL',1);
                }
            }
        }   
        if(section == 'emaps'){

            if(this.modificationID && this.modelID && !this.$route.params.illustrationId){
                
                if(this.THROUGH_VIN && this.THROUGH_VIN.showInEmaps){                      
                    this.$router.push('/emaps/models-'+ this.modelID + '/modification-' + this.modificationID +'/');
                    this.$store.commit('section/SET_PAGE_LEVEL',1);      
                }
            }
        }   
        if(section == 'diagnostic'){
            if(this.THROUGH_VIN && this.THROUGH_VIN.showInDiagnostic){   
                if(this.modificationID && this.modelID && !this.$route.params.illustrationId){
                    this.$router.push('/diagnostic/models-'+ this.modelID + '/modification-' + this.modificationID +'/');
                    this.$store.commit('section/SET_PAGE_LEVEL',1);       
                }
            }
            if(this.defectId){
                this.setPropDiagnostic({ meta: "currentComponent", data: "Session" });
                this.$router.push('/diagnostic/models-'+ this.modelID + '/modification-' + this.modificationID +'/');
                this.$store.commit('section/SET_PAGE_LEVEL',1);
            }
            if(this.$route.params.productModificationId){
                this.hideBar = true;
            }
            else{
                this.hideBar = false;
            }
            
        }   
    },
    section(newVal, oldVal) {
        this.selectedTab = newVal;
        this.previousTab = oldVal;
    },
  }
}
</script>
<style scoped>
    .header_st0,.header_st1{fill:#014637;}
    .header_st1{font-size: 8px;font-family: Arial;color: #014637;font-weight: 600;letter-spacing: -0.03em;}
    .header_st2{font-size: 10px;font-family: Arial;fill: #014637;font-weight: 800;letter-spacing: -0.03em;}  
    .uaz__header{
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 66px;
    }  
    .uaz__logo_wrapper{
        width: 95px;

        z-index: 10;
        padding: 5px 0 5px 10px;
        height: 100%;
        background-color: #fff;
    }
    .uaz__logo_wrapper::after{
        content: '';
        width: 0;
        height: 0;
        top: 1px;
        position: absolute;
        border-style: solid;
        border-width: 0 20px 65px 0px;
        border-color: transparent transparent #ffffff transparent;
    }

    .uaz__logo_wrapper svg{
        position: relative;
        z-index: 9;
        width: 100%;
        height: 65px;
    }
    .uaz-header, .uaz__icon-bar, .uaz__icons-double {
        background-color: #013e2c;
    }
    .uaz__header__logo_part {
        padding: 5px 5px 4px 13px;
    }
    .uaz__header__logo_part img{
        height: 55px;
    }
    .uaz__header__logo_part::before{
       content: '';
        width: 0;
        height: 0;
        margin-left: -33px;
         top: 1px;
        position: absolute;
        border-style: solid;
        border-width: 0 0 65px 20px;
        border-color: transparent transparent #ffffff transparent;
    }
     .uaz__header__lang_button::after {
        position: absolute;
        top: -9px;
        left: 41px;
        right: 0;
        content: '^';
        font-family: cursive;
        font-size: 23px;
        color: #fff!important;
        transform: rotate(180deg );
        padding: 0 0 2px;
        pointer-events: none;
    }
    .uaz__header__doc_button::after{
        position: absolute;
        top: -9px;
        left: 71px;
        right: 0;
        content: '^';
        font-family: cursive;
        font-size: 23px;
        color: #fff!important;
        transform: rotate(180deg );
        padding: 0 0 2px;
        pointer-events: none;
    }
    .uaz__header___menu_part {
        display: flex;
        width: 210px;
        justify-content: flex-start;
    }
    
    .uaz__header___menu_part div{
        line-height: 65px;
        color:white;
        font-size: 10px;
        font-weight: bold;
        margin-left: 14px;
    }
    .el-menu--horizontal .el-menu .el-menu-item{
        background: white !important;
        color:black;
    }
    .el-menu--popup{
        background: white!important;
    }
    .uaz__header___tab_part,.uaz__header___flex_part, .uaz__header___menu_part{
        background: #013e2c;
    }
    .uaz__header___flex_part {
        flex: auto;
    }
    .tab_wrapper {
        margin-left: 20px;
        position: relative;
        top: 45px;
    }
    .uaz__header__doc_button,.uaz__header__lang_button{
        position: relative;
    }
    .uaz__subheader {
        height: 60px;
        padding: 1px 10px 0px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
	.uaz__bar_header {
        z-index: 99;
	    display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: 19px;
    }
    .uaz__header__logout, .uaz__logo_wrapper{
        cursor: pointer;
    }
    .search_block {
        display:  flex;
    }
    #header-middle {
        display: flex;
        align-items: flex-start;
        /*margin-top: 19px;*/
        margin-top: 18.5px;
    }
	
	.uaz__subheader_diagnostic {
		width: 100%;
	}
	.uaz__subheader_diagnostic .search_block {
		width: 500px;
	}
	.uaz__subheader_diagnostic .uaz__bar_header {
		width: calc(100% - 490px);
	}
	.uaz__subheader_list,
	.uaz__subheader_test,
	.uaz__subheader_result,
	.uaz__subheader_session,
	.uaz__subheader_report {
		min-width: 1280px;
	}
	header {
		min-width: 1280px;
	}
	/*.uaz__subheader_list .search_block,
	.uaz__subheader_test .search_block,
	.uaz__subheader_result .search_block,
	.uaz__subheader_session .search_block,
	.uaz__subheader_report .search_block - Поиск - увеличивал, влиял на хедер {
		width: 1042px;
	}*/
	.uaz__subheader_list .uaz__bar_header,
	.uaz__subheader_test .uaz__bar_header,
	.uaz__subheader_result .uaz__bar_header,
	.uaz__subheader_session .uaz__bar_header,
	.uaz__subheader_report .uaz__bar_header {
		width: calc(100% - 1042px);
	}
	
	
	
	
	
.el-menu--horizontal ul {
    border-radius: 50%;
}
.el-menu--horizontal .submenu_select_option {
	font-size: 10px;
    font-family: Arial;
    color: #013e2c!important;
    height: 28px;
    cursor: pointer;
}
.el-menu--horizontal .submenu_select_option {
     padding-left: 20px;
}	
.el-menu--horizontal .is-active.submenu_select_option {
    background-color: #cfdbd9!important;
}


</style>
