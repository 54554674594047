<template>
<div class="scroll"><div class="item_container">
	<div class="illustration_wrapper_breadcrumbs">
		<div class="illustration_breadcrumbs">
			<span>Патриот</span> > 
			<span>316300-0000486-62</span> > 
			<span>Детали двигателя</span> >
			<span>Фильтр воздушный</span> > Дроссельный модуль
		</div>
	</div><br>
    <div class="item_heading">Дроссельный модуль (040904114809000)</div>
  
	<div class="card_wrapper">
		<div class="images_block">
		  <div class="main_subimages">
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/040904114809000.jpg"  /></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/220600110915700.jpg" /></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/316300110913800.jpg" /></div>
			<div class="arrow-block"></div>
		  </div>
		  <div class="main_image"><img class="img_main" src="https://elis.uaz.ru//img/part/IMAGE/040904114809000.jpg" /><img class="plashka_4" src="../assets/test4.jpg"></div>		  
		</div>
	   
		<div class="busket_block">
		  <div class="busket_block_line"><b>Цена:</b> <s style="margin-left: 233px;">2000 ₽</s></div>
		  <div class="busket_block_line"><b>Скидка:</b> <span style="margin-left: 210px;">300 ₽ (-15%)</span></div>
		  <div class="busket_block_line"><b>Окончательная цена:</b> <b style="margin-left: 47px;">1700 ₽</b></div>
		  <img class="plashka_2" src="../assets/test2.jpg">
		  <img class="plashka_6" src="../assets/test6.jpg">
		</div>
	</div>
    <div class="tab_block"><img class="plashka_3" src="../assets/test3.jpg">
      <div class="tab_block_line"></div>
    </div>
    
	<div class="info_block">
		<div class="detail_row">
		  <div class="left_part">Брэнд:</div>
		  <div class="right_part"> <img class="plashka_5" src="../assets/test5.jpg"></div>
		</div>
		<div class="detail_row">
		  <div class="left_part">Артикул:</div>
		  <div class="right_part">040904114809000</div>
		</div>
		<div class="detail_row">
		  <div class="left_part">Примечание:</div>
		  <div class="right_part">Дроссель (для а/м УАЗ, ПАЗ, ДВ. ЗМЗ 40904, 5245)</div>
		</div>
		<div class="detail_row">
		  <div class="left_part">Применимость:</div>
		  <div class="right_part">УАЗ Патриот, УАЗ Профи, УАЗ Cargo</div>
		</div>
		<div class="detail_row">
		  <div class="left_part">Замена:</div>
		  <div class="right_part">040904114809001</div>
		</div>
		<div class="detail_row">
		  <div class="left_part">Количество:</div>
		  <div class="right_part">1</div>
		</div>
		<div class="detail_row">
		  <div class="left_part">Гарантия:</div>
		  <div class="right_part"><a href="*" style="color:#013e2c">Подробнее о гарантии</a></div>
		</div>  
		<div class="detail_row uaz__inline bulletin">
			<div class="uaz__inline uaz__notice-icon"><img src="/img/warning.ff78e2c0.png" style="width: 90%;filter: grayscale(100%);"></div>
			<span><b>ВНИМАНИЕ:</b> Бюллетень №34</span>
		</div>
		<div class="detail_row" style="font-size:12px;">Перейти на иллюстрацию&#8194;<a href="*" style="color:#013e2c">Фильтр воздушный</a></div>
	</div>

	<div class="item_heading">Связанные товары</div>
	
    <div class="section_title">
		<div class="main_subimages">
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/040904114809000.jpg" /><div class="carousel_text"><div><a href="*" style="color:#013e2c">Дроссельный модуль</a></div><div>040904114809000</div><div>14 971 ₽</div></div></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/316300110950000.jpg" /><div class="carousel_text"><div><a href="*" style="color:#013e2c">Шланг компенсирующий</a></div><div>316300110950000</div><div>1 469 ₽₽</div></div></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/220695110916500.jpg" /><div class="carousel_text"><div><a href="*" style="color:#013e2c">Хомут червячныйь</a></div><div>220695110916500</div><div>80 ₽</div></div></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/316000110902010.jpg" /><div class="carousel_text"><div><a href="*" style="color:#013e2c">Крышка воздушного фильтра</a></div><div>316000110902010</div><div>80 ₽</div></div></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/316000110902810.jpg" /><div class="carousel_text"><div><a href="*" style="color:#013e2c">Уплотнитель крышки воздушного фильтра</a></div><div>316000110902810</div><div>74 ₽</div></div></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/316006110908000.jpg" /><div class="carousel_text"><div><a href="*" style="color:#013e2c">Фильтр воздушный</a></div><div>316006110908000</div><div>760 ₽</div></div></div>
			<div class="main_subimage"><img src="https://elis.uaz.ru//img/part/IMAGE/316000110905020.jpg" /><div class="carousel_text"><div><a href="*" style="color:#013e2c">Корпус воздушного фильтра</a></div><div>316000110905020</div><div>467 ₽</div></div></div>
		</div>
		<div class="owl_nav">
			<div class="owl_prev"></div>
			<div class="owl_next"></div>
		</div>
	</div><br>

    <div class="item_heading">Совместимость</div>
	<div class="fitment-table-wrap fitment-closed">
		<table class="fitment-table">
			<thead>
				<tr>
					<th scope="col" class="fitment-year">Год</th>
					<th scope="col" class="fitment-make">Производство</th>
					<th scope="col" class="fitment-model">Модель</th>
					<th scope="col" class="fitment-trim">Модификации</th>
				</tr>
			</thead>
			<tbody>
				<tr class="fitment-row ">
					<td class="fitment-year">2022</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Патриот</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2022</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Пикап</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2022</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Профи</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2021</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Патриот</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2021</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Пикап</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24... </td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2021</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Профи</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24... </td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2019</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Патриот</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2019</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Пикап</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24... </td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2019</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Профи</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2018</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Патриот</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2018</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Пикап</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2018</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Профи</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2017</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Патриот</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2017</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Пикап</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr> <tr class="fitment-row ">
					<td class="fitment-year">2017</td>
					<td class="fitment-make">УАЗ</td>
					<td class="fitment-model">Профи</td>
					<td class="fitment-trim">316300-0000486-62, 316300-0000385-54, 316300-0000485-54, 316300-0000185-24...</td>
				</tr>
			</tbody>
		</table>
	  <a class="fitment-expander">
		<span class="fitment-chevron">v</span>
		(<span class="closed-count">Показать ещё 81</span>)
	  </a>
	</div>
</div></div>
</template>

<script>
// @ is an alias to /src
import { ElLoading } from 'element-plus'
export default {
  name: 'Home',
  data() {
      return {
        
      }
  },
  components: {

  },

}
</script>
<style scoped>
.item_heading{
  font-size: 24px;
  padding-bottom: 20px;
  color: #013e2c;
}
.item_heading_line{
  height: 2px;
  margin: 8px 0;
}
.chars_heading_line{
  background: #013e2c;
  height: 2px;
  margin: 0 0 0px 0;
}
.images_block {
    width: 720px;
    display: flex;
    flex-direction: row;
}
.main_subimages {
    display: flex;
    flex-direction: column;
}
.section_title .main_subimages {
    display: flex;
    flex-direction: row;
    padding: 0 4px;
}
.main_subimage img{
    width: 155px;
    height: 105px;
    border: 1px solid #013e2c;
    margin-right: 11px;
}
.section_title .main_subimages .main_subimage img{
    width: 164px;
}
.main_image{
  width:600px;
  margin-bottom: 15px;
}
.main_image .img_main{
	width: 100%;
    height: 356px;
}
.main_image img{
  border: 2px solid #013e2c;
}
.card_wrapper {
    display: flex;
}
h1.chars_title {
    font-size: 30px;
    text-transform: uppercase;
    font-style: italic;
    font-stretch: semi-expanded;
    letter-spacing: 3px;
    color: #013e2c;
      padding: 9px 20px;
    margin: 0;
}
.chars_block_item {
    font-size: 25px;
    padding: 11px 0px 10px 20px;
    color: #013e2c;
    letter-spacing: 4px;
    font-style: italic;
}
.chars_block {
    padding: 15px 0px;
}
.item_container {
    width: 1223px;
    margin: 0 auto;
} 
.arrow-block {
    background: #013e2c;
    width: 155px;
    height: 30px;
    margin-top: 5px;
}
.busket_block {
    font-size: 24px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
}
img.plashka {
    padding: 16px 0 0 0;
}
img.plashka_3 {
    position: relative;
    left: -14px;
    z-index: 1000; 
}
.tab_block_line {
    background: #ebebeb;
    height: 3px;
    position: relative;
    top: -21px;
    z-index: 1;
}
.detail_row {
    display: flex;
    width: 100%;
    font-size: 17px;
    font-weight: 100;
    height: 40px;
}
.left_part {
    width: 192px;
    FONT-WEIGHT: 600;
    color: black;
}
.info_block {
    padding: 0px 0 25px 0px;
}

img.plashka_4 {
    position: relative;
    left: -103px;
    top: -30px;
}
img.plashka_5 {
    width: 70px;
    position: relative;
    left: -12px;
    top: -10px;
}
img.plashka_6 {
    position: relative;
    left: -16px;
    width: 520px;
    height: 157px;
}
img.plashka_2 {
    padding-top: 20px;
}
.busket_block_line {
    height: 47px;
}


/* скролл */
.scroll {
    height: 100%;
    padding: 0 25px 0 0;
    overflow-y: auto;
    overflow-x: hidden;
	background: #cfdbd9;
	background: #fff;
}
.scroll::-webkit-scrollbar{
  width: 20px;
  overflow: hidden;
}
.scroll::-webkit-scrollbar-track {
  background: #cfdbd9;
  background: #fff;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #7fa099;
  border-radius: 8px;
  border: 5px solid #cfdbd9;
  border: 5px solid #fff;
  background-clip: content-box;
}


/**/
.fitment-table-wrap {
    position: relative;
    font-family: Arial;
    font-size: 14px;
	font-weight: normal;
}
.fitment-table-wrap .fitment-table {
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
	text-align: left;
}
table {
    background-color: transparent;
    border-collapse: collapse;
}
.fitment-table-wrap .fitment-table thead {
    background-color: #f7f7f7;
    border-bottom: 1px solid #aaa;
}
.fitment-table-wrap .fitment-table td, .fitment-table-wrap .fitment-table th {
    padding: 8px 10px 8px 25px;
}
.fitment-table tbody tr.fitment-row {
border-bottom: 1px solid #cfcfcf;
}
.fitment-table-wrap .fitment-expander {
    background-color: #f7f7f7;
    display: block;
    padding: 5px;
    text-align: center;
    transition: all .5s ease;
    width: 100%;
	color: #0068b3;
	cursor: pointer;
}
.fitment-chevron {
	display: inline-block;
    transform: scale(2.1, 0.9);
    position: relative;
    bottom: 1px;
    right: 3px;
}

.illustration_wrapper_breadcrumbs {
    height: 23px;
}
.illustration_breadcrumbs {
    color: #757575;
    display: inline-block;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 0;
    margin-top: 2px;
}
.illustration_breadcrumbs span {
    color: #1a7db7; /*синий*/	
    color: #013e2c; /*зеленый*/	
	cursor: pointer;
}
.owl_nav {
	-webkit-tap-highlight-color: transparent;
    display: block;
    position: relative;
    width: 100%;
    z-index: 1;
    margin: 0;
}
.owl_nav .owl_prev, .owl_nav .owl_next {
    cursor: pointer;
    width: 22px;
    background: #f7f7f7;
    position: absolute;
    text-align: center;
    bottom: 97px;
}
.owl_nav .owl_prev {
    left: -8px;
}
.owl_nav .owl_next {
    right: -4px;
}
.owl_nav .owl_prev:before {
    content: "\F053";
}
.owl_nav .owl_next:before {
    content: "\F054";
}
.owl_nav .owl_next:before, .owl_nav .owl_prev:before {
    -webkit-font-smoothing: antialiased;
    color: #013e2c;
    display: inline-block;
    font-family: FontAwesome;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 5;
    position: relative;
}
.owl_nav .owl_next:hover, .owl_nav .owl_prev:hover {
    background: #e9e9e9;
}
.uaz__inline {
    display: inline-block;
}
.bulletin {
    color: #013e2c; /*зеленый*/	
}
.bulletin span {
    margin-left:8px;
}
.uaz__notice-icon {
    width: 20px;
    height: 20px;
    vertical-align: top;
}
.carousel_text {
	text-align:center;
	font-size: 14px;
	width: 161px;
	font-weight: normal;
}
.carousel_text div {
    margin-top: 4px;
}
</style>
