const state = {
  activeLanguage : 'RU',
};

// Getter functions
const getters = {
  getActiveLanguage: state => {
    return state.activeLanguage;
  }
};

// Actions
const actions = {
};
// Mutations
const mutations = {
  SET_ACTIVE_LANGUAGE(state, data) {
    state.activeLanguage = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
