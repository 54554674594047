
import { defineComponent, ref } from 'vue'
import {Request} from "@/services/axios.service";
import { errorMixin } from "@/plugins/error.plugin";
export default defineComponent({
  setup () {
    const isShow = ref(false)
    const user_email = ref('')

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }
	  function restorePassword(){
      var dataToSend = new FormData(); console.log(11)
      if(this.user_email){
        return Request.post('/user/restorePassword',
          {
            email: this.user_email
          }
          ).then((response) => {			

          }).catch(function (error) {
            errorMixin.methods.showError(error.response.data.message);
          });
      }
    }
    return {
      restorePassword,
      isShow,
      showModal,
      closeModal,
	  user_email,
    }
  }

})
