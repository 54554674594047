<template>
<div class="uaz_parts_container"> 
<div class="oem-assemblies-module">
<div class="part-group pp-standard">
<div class="part-group-container multiple-diagrams" style="display: flex;">

<div class="pp-image-first-container" id="parts_diagram_0">
 <div class="illustration_wrapper_breadcrumbs"><div class="illustration_breadcrumbs">
  <span>Патриот</span> > 
  <span>316300-0000486-62</span> > 
  <span>Детали двигателя</span> > Фильтр воздушный</div>
 </div>
 <div class="pp-image-double-container">
  <div class="uaz_button_background_white">
    <div class="uaz__light-icon uaz__icons-increase ng-isolate-scope item">
      <img src="https://v2.elis.uaz.ru/img/increase-img.25113f98.svg"></div>
    <div class="uaz__light-icon uaz__icons-decrease item">
      <img src="https://v2.elis.uaz.ru/img/decrease-img.0dda8434.svg"></div>
    <div class="uaz__light-icon uaz__icons-fullscreen item" id="uaz__full-screen">
      <img src="https://v2.elis.uaz.ru/img/full-screen.e18665ae.svg"></div>
    <div class="uaz__light-icon uaz__icons-carousel-on item" id="uaz__full-screen"></div>
    <div class="uaz__light-icon uaz__icons-resize-off item" id="uaz__full-screen"></div>
  </div>
  <div class="pp-image-container">
    <div class="pp-image"><img class="parts-diagram" src="https://i.ibb.co/RjpXnfc/tests.png"></div>
    <div class="pp-image-tray pp-image-tray-open"> <div class="tray-icon"></div> </div>
  </div>
 </div>
</div>

<div class="all-parts-table-container"> <div class="all-parts-table2"> <div class="all-component-parts  marketplace-parts-table">
<div class="row label-row"> <div class="reference-code-col col-xs-1">Поз.</div>
<div class="product-image-col col-xs-2"></div><div class="product-details-col col-xs-5">Наименование / Артикул / Цена</div> </div>

<div class="scroll">
<div class="catalog-product marketplace-product row part-row alternate">
<div class="reference-code-col col-xs-1"> <span>1</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/040904114809000.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Дроссельный модуль</a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 14 971₽</div>   </div> <div class="product-partnum">
<a href="">040904114809000</a>
</div><div class="product-more-info"> <p class="specific_description">Дроссель (для а/м УАЗ, ПАЗ, ДВ. ЗМЗ 40904, 5245)</p> </div>
</div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div><div class="catalog-product marketplace-product row part-row">
<div class="reference-code-col col-xs-1"> <span>2</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316300110950000.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="" >Шланг компенсирующий</a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 1 469₽</div>   </div> <div class="product-partnum">
<a href="">316300110950000</a>
</div><div class="product-more-info"> <p class="specific_description"></p> </div></div>
<div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div><div class="catalog-product marketplace-product row part-row alternate">
<div class="reference-code-col col-xs-1"> <span>3</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/220695110916500.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Хомут червячный</a>
</strong> <div class="product-pricing"> <div class="list-price-only">80₽</div></div>
<div class="product-partnum">
<a href="" >220695110916500</a>
</div></div><div class="col-xs-12 col-sm-4"><div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div><div class="catalog-product marketplace-product row part-row">
<div class="reference-code-col col-xs-1"> <span>4</span></div><div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/220695110916500.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Хомут червячный (70-90)</a>
</strong> <div class="product-pricing"><div class="list-price-only"> 60₽</div></div><div class="product-partnum">
<a href="">220695110916501</a>
</div></div><div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div><div class="catalog-product marketplace-product row part-row alternate">
<div class="reference-code-col col-xs-1"> <span>5</span> </div>
<div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316000110902010.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Крышка воздушного фильтра</a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 80₽</div></div><div class="product-partnum">
<a href="">316000110902010</a> </div>
<div class="product-more-info"> <p class="specific_description"></p> </div> </div>
<div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div><div class="catalog-product marketplace-product row part-row">
<div class="reference-code-col col-xs-1"> <span>6</span> </div><div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316000110902810.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"><div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Уплотнитель крышки воздушного фильтра</a>
</strong><div class="product-pricing"> <div class="list-price-only">74₽</div></div><div class="product-partnum">
<a href="">316000110902810 </a>
</div></div><div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div><div class="catalog-product marketplace-product row part-row alternate">
<div class="reference-code-col col-xs-1"> <span>7</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316006110908000.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Фильтр воздушный </a>
</strong><div class="product-pricing"> <div class="list-price-only">760₽ </div></div><div class="product-partnum">
<a href="">316006110908000 </a>
</div><div class="product-more-info"> <p class="specific_description">Фильтр воздушный (для а/м УАЗ ПАТРИОТ, ХАНТЕР, ДВ. ЗМЗ 409, ЕВРО 4,5)</p> </div> </div>
<div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div>
<div class="catalog-product marketplace-product row part-row">
<div class="reference-code-col col-xs-1"> <span>8</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap flip" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316000110905020.jpg" class="lazy-load animated full-res loaded"></a>
</div><div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Корпус воздушного фильтра </a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 467₽ </div>   </div> <div class="product-partnum">
<a href="">316000110905020 </a>
</div><div class="product-more-info"> <p class="specific_description"></p> </div>
</div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div> <div class="catalog-product marketplace-product row part-row alternate">
<div class="reference-code-col col-xs-1"> <span>9</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/000000025200629.jpg" class="lazy-load placeholder animated"> </a>
</div> <div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Шайба 10 </a></strong> <div class="product-pricing"> <div class="list-price-only"> 270₽ </div>   </div> <div class="product-partnum">
<a href="">000000025200629 </a>
</div></div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div></div></div></div> <div class="catalog-product marketplace-product row part-row">
<div class="reference-code-col col-xs-1"> <span>10</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316000110901011.jpg" class="lazy-load placeholder animated"> </a>
</div> <div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Корпус воздушного фильтра в сборе </a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 7 310₽ </div>   </div> <div class="product-partnum">
<a href="">316000110901011 </a>
</div><div class="product-more-info"> <p class="specific_description"></p> </div>
</div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div></div></div></div><div class="catalog-product marketplace-product row part-row alternate">
<div class="reference-code-col col-xs-1"> <span>11</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316300110913800.jpg" class="lazy-load placeholder animated"> </a>
</div> <div class="product-details-col col-xs-9 row">
<div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Кронштейн крепления воздушного фильтра </a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 314₽ </div>   </div> <div class="product-partnum">
<a href="">316300110913800 </a>
</div></div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div> </div> </div> </div>
<div class="catalog-product marketplace-product row part-row">
<div class="reference-code-col col-xs-1"> <span>12</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/315120110943020.jpg" class="lazy-load placeholder animated"> </a>
</div>
<div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Хомут крепления воздушного фильтра </a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 450₽ </div>   </div> <div class="product-partnum">
<a href="">315120110943020 </a>
</div></div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div></div></div></div>
<div class="catalog-product marketplace-product row part-row alternate">
<div class="reference-code-col col-xs-1"> <span>13</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/220600110915700.jpg" class="lazy-load placeholder animated"> </a>
</div>
<div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Хомут стяжной </a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 134₽ </div>   </div> <div class="product-partnum">
<a href="">220600110915700 </a>
</div></div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div></div></div></div>
<div class="catalog-product marketplace-product row part-row">
<div class="reference-code-col col-xs-1"> <span>14</span> </div> <div class="product-image-col col-xs-2">
<a href="" class="product-image-link lazy-load-wrap" data-routing="ignore">
<img src="https://elis.uaz.ru//img/part/IMAGE/316300110919200.jpg" class="lazy-load placeholder animated"> </a>
</div>
<div class="product-details-col col-xs-9 row"> <div class="col-xs-12 col-sm-7"> <strong class="product-title">
<a href="">Шланг соединительный </a>
</strong> <div class="product-pricing"> <div class="list-price-only"> 135₽ </div>   </div> <div class="product-partnum">
<a href="">316300110919200 </a>
</div></div> <div class="col-xs-12 col-sm-4"> <div class="part-purchase-button">
<a href="" class="action-button place-order-button no-icon"><span>В корзину</span></a>
</div></div></div></div>
</div>

</div> </div> </div></div></div></div>	
</div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios');
import {Request} from "@/services/axios.service";
import { ElLoading } from 'element-plus'
export default {
  name: 'Test',
  data() {},
  components: {},
  methods:{},
}
</script>
<style scoped>
.oem-assemblies-module .part-group .pp-image-container .hotspot {
    background-color: #fff;
    border: 1px dashed #1c87c6;
    color: #1a7db7; /*синий*/
    color: #013e2c; /*зеленый*/		
    cursor: pointer;
    display: block;
    font-size: 14px;
    position: absolute;
    z-index: 10;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot .hotspot-menu {
    display: none;
    padding-left: 7px;
    position: absolute;
    z-index: 11;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot-code {
    display: block;
    font-weight: normal;
    margin: -1px;
    text-align: center;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot .hotspot-menu ul {
    background-color: #1a7db7;
    color: #fff;
    display: table;
    font-size: 12px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 250px;
    z-index: 11;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot .hotspot-menu li:last-child {
    border-bottom: 0;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot .hotspot-menu li {
    border-bottom: 1px solid #fff;
    display: table-row;
    margin: 0;
    min-height: 25px;
    padding: 0;
    position: relative;
    width: 100%;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot .hotspot-menu li .hotspot-cart {
    border-right: 1px solid #5e9dc4;
    color: #fff;
    display: table-cell;
    padding: 10px;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot .hotspot-menu li .hotspot-content {
    color: #fff;
    display: table-cell;
    line-height: normal;
    padding: 5px;
    text-decoration: none;
    vertical-align: top;
    width: 210px;
}
.oem-assemblies-module .part-group .pp-image-container .hotspot .hotspot-menu li>* {
    border-bottom: 1px solid #5e9dc4;
}
.oem-assemblies-module .part-group .part-group-container {
    padding-bottom: 30px;
    position: relative;
    width: 100%;
	display: flex;
}
.oem-assemblies-module .part-group .pp-image {
    margin: 8% auto;
    position: relative;
	width: -webkit-fill-available;
    height: -webkit-fill-available;
    min-height: 690px;
}
.oem-assemblies-module .part-group img.parts-diagram {
    max-width: 100%;
    vertical-align: middle;
    border-style: none;	
    left: 0;
    position: absolute;
    top: 0;
	width: -webkit-fill-available;
    height: -webkit-fill-available;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts a img {
    margin: auto;
    max-height: 100%;
    max-width: 100%;
}
img {
    max-width: 100%;
}
.oem-assemblies-module .part-group .pp-image-tray {
    display: none;
}
.oem-assemblies-module .part-group .part-group-container:after {
    clear: both;
    content: "";
    display: table;
}
.col-xs-5 {
    float: left;
    width: 41.66667%;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .label-row .mobile-add-to-cart {
    display: none;
}
@media (min-width: 1200px) .row {margin-left: -15px;margin-right: -15px;}
@media (min-width: 992px) .row {margin-left: -15px;margin-right: -15px;}
@media (min-width: 768px) .row {margin-left: -15px;margin-right: -15px;}
.row {margin-left: -15px;margin-right: -15px;}
html body .action-button:not(.add-to-wishlist) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
    cursor: pointer;
    color: white;
    border-radius: 2px;
    transition: all 0.2s ease 0s;
    background: #013e2c;
    padding: 11px 14px;
	margin-left: 100px;
	white-space: nowrap;
}
html body .action-button:not(.add-to-wishlist) span {
	font-family: Arial;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
}
html body .action-button:after {
    content: "\F105";
    display: inline-block;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    margin-left: 10px;
    vertical-align: bottom;
}
html body .action-button.no-icon:after {
    content: "";
    margin-left: 0;
}
.oem-assemblies-module .part-purchase-button {
    margin-top: 10px;
}
.col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    min-height: 1px;
    padding-left: 19px;
    padding-right: 15px;
    position: relative;
}
.col-xs-12 {
    float: left;
    width: 100%;
}
@media (min-width: 1200px) .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 992px) .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 768px) .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 576px) .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 576px) .col-sm-7 {
    float: left;
    width: 58.33333%;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .product-details-col .product-title {
    font-size: 16px;
    font-weight: normal;
}
.product-details-col.col-xs-5 {
	padding-left: 5px;
}
.col-xs-12.col-sm-4 {
	padding-left: 0px;
}
strong {
    font-family: Arial;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .product-details-col .product-partnum {
    font-size: 14px;
    margin: 0px 0;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .product-details-col .product-more-info {
    font-size: 11px;
    /*line-height: 1.7em;*/
	font-weight:normal;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .contextual_description, .oem-assemblies-module .part-group .part-group-container .all-component-parts .specific_description {
    font-size: 9pt;
    margin-top: 0px;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts li, .oem-assemblies-module .part-group .part-group-container .all-component-parts p, .oem-assemblies-module .part-group .part-group-container .all-component-parts ul {
    margin: 0;
    padding: 0;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .product-details-col .product-partnum a {
    font-weight: normal;
}
.row:after {
    clear: both;
    content: "";
    display: table;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .product-image-col>a {
    background-color: #fff;
    border: 1px solid #cfcfcf;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    height: 100px;
    overflow: hidden;
}
.lazy-load-wrap {
    position: relative;
}
.lazy-load-wrap.flip .full-res {
    opacity: 1;
}
img.lazy-load.placeholder.animated {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: lazyLoadPlaceHolderShimmer;
    animation-timing-function: linear;
    background: #eee;
    background: linear-gradient(90deg,#f9f9f9 13%,#ddd 33%,#f9f9f9 47%);
    background-size: 200px;
}

@media (prefers-reduced-motion: reduce)
img.lazy-load.placeholder.animated {
    animation: none;
}
img.lazy-load.placeholder {
    height: 100%;
    opacity: 1;
    width: 100%;
}
.lazy-load-wrap.flip .placeholder {
    opacity: 0;
}
img.lazy-load {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
	min-width: 100%;
    min-height: 100%;
    flex-shrink: 0;	
    position: absolute;
    transition: opacity .25s ease-in-out;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .reference-code-col {
    text-align: left;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product {
    border-bottom: 1px solid #f5faf9;
    border-bottom: 1px solid #cfdbd9;		
    border-bottom: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfdbd959;	
    padding: 3px 0;
}
a {
    color: #1a7db7; /*синий*/
	color: #013e2c; /*зеленый*/		
    text-decoration: none;
    font-weight: bolder;
}
.oem-assemblies-module .all-component-parts.marketplace-parts-table .marketplace-product .list-price-only {
    color: #333;
    font-size: 14px;
    font-weight: bolder;
    line-height: 20px;
    font-family: 'Comic Sans MS', cursive;
}
.reference-code-col span {
    padding-left: 10px;
    color: rgb(51, 51, 51);
    font-size: 15px;
    line-height: 25px;
    font-weight: normal;
	font-family: Arial;
}
.oem-assemblies-module .part-group .part-group-container .pp-image-first-container {
	width: 30%;
    background-color: #fff;
    max-width: 100%;
    z-index: 99 !important;
    margin: 0px;
}
.pp-image-double-container {
    border: 1px solid #cfcfcf;
    height: 833px;	
}
.oem-assemblies-module .part-group .part-group-container.multiple-diagrams #parts_diagram_0 {
	width: 50%;
	margin-right: 20px;
}
.part-group.pp-standard {
	height: 888px;
	display: flex;
}
.all-component-parts {
    margin: 0 auto;
    width: 100%;
	height: 935px;
    overflow: hidden;
}	
/* скролл */
.all-component-parts .scroll {
	width: 100%;
    height: 89%;
    /*padding: 0 25px 0 0;*/
    overflow-y: auto;
    overflow-x: hidden;
	background: #cfdbd9;	
	background: #fff; /*белый*/
}
.scroll .alternate {
    background: #f5faf9;
    background: #cfdbd9;
    background: #cfdbd959;	
}
.all-component-parts .scroll::-webkit-scrollbar{
  width: 20px;
  overflow: hidden;
}
.all-component-parts .scroll::-webkit-scrollbar-track {
  background: #fff; /*белый*/	
  background: #cfdbd9;
}
.all-component-parts .scroll::-webkit-scrollbar-thumb {
  background-color: #7fa099;
  border-radius: 8px;
  border: 5px solid #fff; /*белый*/	
  border: 5px solid #cfdbd9;
  background-clip: content-box;
}

.oem-assemblies-module .part-group .part-group-container .all-parts-table-container {
    min-height: 400px;
    overflow: hidden;
    width: 100%;
	width: 50%;
    background: #cfdbd9;
    background: #ffffff;
}
.oem-assemblies-module {
    width: 100%;
}
.col-xs-9 {
    float: left;
    width: 75%;
}
.col-xs-2 {
    float: left;
    width: 20.66667%;
}
.col-xs-1 {
    float: left;
    width: 5.33333%;
}
.col-sm-7 {
    float: left;
    width: 63.33333%;
}
.col-sm-4 {
    float: left;
    width: 30.33333%;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .label-row {
    border-bottom: 1px solid #cfcfcf;
    font-weight: 600;
    display: flex;
    justify-content: start;
    color: #013e2c;
    font-family: Arial;
    font-size: 14px;
    width: 107%;
    height: 24px;
	padding: 4px 5px;
}
.oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .product-image-col>a:active, .oem-assemblies-module .part-group .part-group-container .all-component-parts .catalog-product .product-image-col>a:hover {
    border-color: #49abe6;
}
html body .action-button:not(.add-to-wishlist):hover {
    background: #013e2cdb;
    color: white;
}
.illustration_wrapper_breadcrumbs {
    height: 23px;
}
.illustration_breadcrumbs {
    color: #757575;
    display: inline-block;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 5px;
    margin-top: 2px;
}
.illustration_breadcrumbs span {
    color: #1a7db7; /*синий*/	
    color: #013e2c; /*зеленый*/
	cursor: pointer;
}
.uaz_button_background_white {
    display: flex;
    background: white;
    flex-direction: row;
	justify-content: flex-end;
    align-items: flex-start;
	padding: 5px;

}
.uaz__light-icon {
    background-color: #013e2c;
    margin: 1px 2px;
}
.uaz__light-icon {
    position: relative;
    cursor: pointer;
    width: 17px;
    height: 17px;
    padding: 1px;
    border-radius: 0px;
    right: -10px;
    top: 0px;
}
.uaz_button_background_white .uaz__light-icon {
    right: 0px;
    top: -1px;
}
.uaz__icons-decrease img, .uaz__icons-fullscreen img, .uaz__icons-increase img, .uaz__icons-carousel img {
    width: 100%;
    height: 100%;
}
.uaz__icons-carousel-on {
	background: url(https://elis.uaz.ru/elis.uaz/img/icons.png) -59px -84px;
}
.uaz__icons-resize-off {
	background: url(https://elis.uaz.ru/elis.uaz/img/icons.png) -5px -189px;
}
</style>      