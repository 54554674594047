
const axios = require('axios');
import {Request} from "@/services/axios.service";
import Modal_reg from '@/components/Modal_reg.vue'
import Modal_forgot_password from '@/components/Modal_forgot_password.vue'
import { errorMixin } from "@/plugins/error.plugin";
import Password_box from '@/components/Password_box.vue'
import Modal_confirm from '@/components/Modal_confirm.vue';
export default {
  mixins: [errorMixin],
	name: 'Register',
    data () {
      return {
        ruleFormRef : Object.assign({}, this.ruleForm),
        isShow:false,
        ruleForm:{
          user_lname: '',
          user_fname: '',
          user_mname: '',
          user_phone: '',
          user_email: '',
          user_password: '',
          passwordConfirm: '',
          personal: false
        },
        rules:{
          user_lname: [
            {
              required: false,
              message: 'Заполните поле Фамилия',
              trigger: 'change',
            },
          ],
          user_fname: [
            {
              required: false,
              message: 'Заполните поле Имя',
              trigger: 'change',
            },
          ],
          /*user_mname: [
            {
              required: true,
              message: 'Заполните поле Отчество',
              trigger: 'change',
            },
          ],*/
          user_phone: [
            {
              pattern: '^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$',
              required: false,
              message: 'Заполните поле телефон',
              trigger: 'change',
            },
          ],
          user_email: [
            {
              type: 'email',
              required: true,
              message: 'Заполните поле email',
              trigger: 'change',
            },
          ],
          user_password: [
            {
              required: true,
              message: 'Заполните поле пароль',
              trigger: 'change',
            },
          ],
          passwordConfirm: [
            {
              validator: this.validatePass2,
              required: true,
              trigger: 'change',
            },
          ],
          personal: [
            {
              transform: value => String(value),
              type: 'enum',
              enum: ['true'],
              required: true,
              message: 'Согласие обязательно',
              trigger: 'change',
            },
          ],
        }
      }
    },
	components: {
    "Modal-reg": Modal_reg,
    "Modal-forgot-password": Modal_forgot_password,
    "password-box": Password_box,
    "Modal-confirm" : Modal_confirm,
	},
	methods: {
  submitForm : function(formEl){
    this.$refs.ruleFormRef.validate(valid => {
        if (valid) {
          return Request.post('user/update/',
            this.ruleForm
          ).then((response) => {
          }).catch(function (error) {
            errorMixin.methods.showError('error',error.response.data.message);
          });
        } else {
          return false
        }
      })
    },
  validatePass2 : function (rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error('Пожалуйста, введите пароль ещё раз'))
    } else if (value !== this.ruleForm.user_password) {
      callback(new Error("Пароли не совпадают"))
    } else {
      callback()
    }
  },
  showModal: function(){
    this.isShow = true;
  },
  closeModal: function(){
    this.isShow = false;
  },


	}
}
